import React, { useCallback, useMemo } from "react";

import { useIntegrationsContext } from "../context";

import { makeStyles } from "@material-ui/core/styles";

import Select from "~/components/ControlledSelect";

import { GetIntegrationsQuery } from "@api/graphql/types";
import { SelectOption } from "~/components/ControlledSelect/types";

export const useStyles = makeStyles((theme) => ({
	select: {
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			display: "none",
		},
	},
}));

export interface FieldConfigInputProps {
	companyId: number;
	companyIntegrationId: number;
	config: NN<NN<NN<GetIntegrationsQuery["integrations"]>["nodes"]>[0]["fieldConfigs"]>["nodes"][0]
}

export const FieldConfigSelect = ({ companyId, companyIntegrationId, config }: FieldConfigInputProps) => {
	const { createField, updateField } = useIntegrationsContext();

	const { field, value, options } = useMemo(() => {
		const options = config.options.nodes.map(n => ({ ...n, disabled: !n.enabled }));
		const field = config.fieldsByConfig?.nodes?.[0];
		const value = options.find(option => field?.value === option.value);
		return {
			field,
			value,
			options,
		}
	}, [config]);

	const onSubmit = useCallback(
		async (selected: SelectOption) => {
			if (field?.id) {
				const result = await updateField(field?.id, selected.value);
				console.log("updateField", result);
			} else {
				const result = await createField({ value: selected.value, companyId, config: config.id, companyIntegrationId });
				console.log("createField", result);
			}
		},
		[value, companyIntegrationId],
	);


	const styles = useStyles();

	return (
		<Select
			className={styles.select}
			value={value}
			defaultValue={""}
			label={config.label}
			helper={config.helper}
			setValue={onSubmit}
			options={options}
		/>
	);
};
